export const SkillsList = [
  "HTML5",
  "CSS3",
  "JavaScript",
  "React",
  "SASS",
  "Python",
  "Java",
  "React Native",
  "Bootstrap",
  "JQuery",
  "AJAX",
  "Jekyll",
  "Figma",
  "Latex",
  "SQL",
];
